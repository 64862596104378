import React from 'react';
const PopupCancelOrder = (props) => {
    return (


    <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content box" >
            <p>Vai tiešām vēlaties atcelt rezervāciju?</p>
            <div className={'buttons'}>
                <button onClick={props.handleCancelFalse}  className="button is-danger">NĒ</button>
                <button onClick={props.handleCancelTrue} className="button is-success">
                    JĀ! ATCELT!
                </button>
            </div>
        </div>


    </div>

    );
}

export default PopupCancelOrder;

