import React from 'react';
import {convertPrice, secondsToMinutes} from "../../utils/helper.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationPin, faUser} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {faHourglass} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "gatsby-plugin-react-i18next";
const OrderData = (props) => {
    const {t} = useTranslation();

    return (

<div>



    <div className="columns">
        <div className="column">
            <div className="box notification">
                <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faBell}/>
                        </span> {props.orderData.status_label}</p>
                <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faLocationPin}/>
                        </span> {props.orderData.customer_address.formatted_address}</p>
                <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faHourglass}/>
                        </span> {secondsToMinutes(props.orderData.duration_in_seconds)} {t('minutes')}</p>
                <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faClock}/>
                        </span> {props.orderData.arrival_at_window_with_date}</p>

                        <p><span className="icon  mr-1">
                <FontAwesomeIcon icon={faUser}/>
                        </span> {props.orderData.user.first_name} {props.orderData.user.last_name}</p>

                {parseInt(props.orderData.order_status) === 2   ?   <div className={'buttons'}>
                    <button value={props.orderData.id} onClick={(e) => props.cancelOder(e.target.value)}  className={'button is-danger ml-2 mt-2'}>{t('cancel')}</button>
                </div> : null}



            </div>
        </div>
        <div className="column">


        {props.orderCustomContactInfo &&
            <div className="box">
  <div className="box-header">
    <h2 className="box-title">{t('vehicle_owner_contact_info')}:</h2>
  </div>
  <div className="box-content">
    <p>{props.orderCustomContactInfo.person_name}</p>
    <p>{props.orderCustomContactInfo.phone_with_code}</p>
  </div>
</div>
}

    
            <div className={'box notification is-primary'}>


                {props.orderData.used_credits_in_cents !== 0   ?      <p>{t('used_credits')}: <b>{convertPrice(props.orderData.used_credits_in_cents)}</b></p> : null}
                {props.orderData.used_credits_in_cents !== 0   ?         <p>{t('total_discount')}: <b>{convertPrice(props.orderData.total_discount_in_cents)}</b></p> : null}

                <p>{t('vat')}: <b>{convertPrice(props.orderData.vat_to_pay_in_cents)} ({props.orderData.vat_percent}%)</b></p>

                <div className={'is-size-4'}>{t('total')}: <b className={'is-large'}>{convertPrice(props.orderData.price_in_cents_with_vat)}</b></div>
            </div>
        </div>

    </div>



    <div className={'box'}>
        <h3 className={'title'}>{t('vehicles')}</h3>


        {props.orderData.order_vehicles.map(orderVehicle => (
            <div key={orderVehicle.id} className={'notification'}>
                <h3 className={'is-size-5'}><b>{orderVehicle.customer_vehicle.make_and_model} - {orderVehicle.customer_vehicle.reg_number}</b></h3>
                <h4>{t('services')}:</h4>
                <p className={'is-size-6'}><b>{orderVehicle.service_label} </b></p>

                <ul>
                    {orderVehicle.additional_services.map( additionalService =>
                       <li  key={additionalService.id} >{additionalService.label}</li>
                    )}
                </ul>


            </div>

        ))}
    </div>

</div>



    );
};

export default OrderData;