import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import  {isLoggedIn} from "../utils/auth.service";
import {graphql, navigate} from "gatsby";
import authHeader from "../utils/auth-header";
import LoadingSpinner from "../components/loading-spinner";
import OrderData from "../components/orders/order_data";
import PopupCancelOrder from "../components/popup-cancel-order";
import toast from "react-hot-toast";
import {useTranslation} from "gatsby-plugin-react-i18next";


const ViewOrder = ({ location }) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);

    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });


    const params = new URLSearchParams(location.search);
    const orderId = params.get("id");


    useEffect(() => {

        if(!isLoggedIn()){
            return navigate('/');
        }

        if(orderId === null){
            return navigate('/');
        }

       // loadDefaultFormDataFromServer(location.state.orderId);
        loadDefaultFormDataFromServer(orderId);


    }, [])


    const loadDefaultFormDataFromServer = (orderId) => {


        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        // get data from GitHub api
        fetch(process.env.WASHCAR_API_URL + "/business/orders/view/" + orderId, requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {

                if(resultData.status === 'Error'){
                    alert(resultData.message)
                    return;
                }

                setOrderData(resultData.data);
                setLoading(false)

            }) // set data for the number of stars
    }




    const cancelOder = async (orderId) => {

        setPopup({
            show: true,
            orderId,
        });

    };


    const handleCancelTrue = () => {


        if (popup.show && popup.orderId) {


            try {

                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                };

                fetch(process.env.WASHCAR_API_URL + "/business/cancel-order-by-customer/" + popup.orderId, requestOptions)
                    .then(response => response.json()) // parse JSON from request
                    .then(resultData => {
                        toast.success(resultData.alert.text);
                        return navigate('/dashboard');

                    })


            } catch (err) {
                console.log(err);
            }


            setPopup({
                show: false,
                id: null,
            });
        }
    };

// This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    const handleCancelFalse = () => {

        setPopup({
            show: false,
            id: null,
        });
    };



    return (
        <Layout>

            <Seo title={t('order')}/>

            {loading ? <div className="pos-center"><LoadingSpinner /></div> : <h1 className="title">{t('order_number')} <b>{orderData.order.invoice_number}</b></h1>}

            {popup.show ?  <PopupCancelOrder
                handleCancelTrue={handleCancelTrue}
                handleCancelFalse={handleCancelFalse}
            /> : null}



            {loading ? <div className="pos-center"><LoadingSpinner /></div> : <OrderData cancelOder={cancelOder} orderData={orderData.order} orderCustomContactInfo={orderData.custom_contact_details} />}


        </Layout>
    )
}

export default ViewOrder

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
